import Icon from 'components/atoms/icon'
import Link from 'components/atoms/link'
import SubHead from 'components/atoms/subhead'
import Container from 'components/container'
import Layout from 'components/layout'
import Breadcrumbs from 'components/molecules/breadcrumbs'
import Card from 'components/molecules/card'
import OpenStatus from 'components/molecules/openStatus'
import Seo from 'components/molecules/seo'
import ArticleBody from 'components/organisms/articleBody'
import ArticleFooter from 'components/organisms/articleFooter'
import ArticleHeader from 'components/organisms/articleHeader'
import Carousel from 'components/organisms/carousel'
import { renderDocumentToReactComponents } from 'components/organisms/renderDocumentToReactComponents'
import TableOfContents from 'components/organisms/tableOfContents'
import { createClient } from 'contentful'
import { graphql, PageProps } from 'gatsby'
import { getCurrentLang } from 'helpers/locale'
import { renderLineBreaks } from 'helpers/text'
import { triggerEvent } from 'helpers/tracking'
import usePlacesApi from 'hooks/usePlacesApi'
import { useResourceTextData } from 'hooks/useResourceTextData'
import ChevDown from 'images/svg/chev-down.svg'
import Facebook from 'images/svg/facebook.svg'
import Ig from 'images/svg/ig.svg'
import LinkIcon from 'images/svg/link.svg'
import Marker from 'images/svg/marker.svg'
import Time from 'images/svg/time.svg'
import Twitter from 'images/svg/twitter.svg'
import Web from 'images/svg/web.svg'
import React, { useEffect, useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'
import {
  ContentfulCafe,
  FileConnection,
  SitePageContext,
} from 'types/graphql-types'

const CafeMeta = styled.div`
  ${({ theme }): CSSProp => css`
    font-size: 1.3rem;
    font-weight: ${theme.font.fontWeightLight};
    line-height: 1.5;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      font-size: 1.4rem;
    }
    @media only screen and ${theme.breakpoints.fromNormalScreen} and ${theme.breakpoints.toLargeScreen} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 2rem;
    }
    > div:not(:last-child) {
      margin-bottom: 3rem;
    }
    a {
      text-decoration: none;
    }
    a:not([href*='tel']) {
      font-weight: normal;
    }
    p {
      margin: 0 0 0.8rem;
    }
    .icon:not(.link-icon) {
      position: absolute;
      left: 0;
      top: 1px;
    }
    a.map-link {
      font-size: 1.1rem;
      letter-spacing: 0.3px;
      font-weight: bold;
      text-decoration: underline;
    }
    .link-icon {
      color: ${theme.colours.tertiary};
      vertical-align: middle;
      width: 11px;
      height: 11px;
      margin-right: 1.3rem;
    }
    .web-link {
      text-decoration: underline;
    }
    .has-icon {
      position: relative;
      padding-left: 3rem;
    }
  `}
`

type CafeTemplateProps = PageProps & {
  data: {
    contentfulCafe: ContentfulCafe
    allFile: FileConnection
  }
  pageContext: SitePageContext
}

const CafeTemplate = (props: CafeTemplateProps): JSX.Element => {
  const { contentfulCafe, allFile } = props.data
  const {
    slug,
    title,
    images,
    filterTags,
    longAddress,
    shortAddress,
    phone,
    openingHours,
    instagram,
    facebook,
    homepage,
    twitter,
    leadingParagraph,
    showTableOfContents,
    body,
    metaDescription,
    googlePlacesId,
    location,
  } = contentfulCafe
  const hasImage = images?.length > 0

  const [related, setRelated] = useState([])

  const [ignoreOpeningHours, setIgnoreOpeningHours ] = useState(false)

  const currentLang = getCurrentLang()

  useEffect(() => {
    const getNearby = async () => {
      const client = createClient({
        space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
        accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
      })

      const response = await client.getEntries({
        // eslint-disable-next-line @typescript-eslint/camelcase
        content_type: 'cafe',
        'fields.location[near]': `${location.lat},${location.lon}`,
        limit: 5,
        locale: currentLang,
      })

      const normalised = response.items
        ?.filter(x => x.fields.slug !== slug)
        .map((item, i) => ({
          id: item.sys.id,
          title: item.fields.title,
          heroImage: {
            fluid: {
              src: item.fields.images?.[0].fields
                ? item.fields.images[0].fields.file.url
                : allFile.nodes[i % allFile.nodes.length].childImageSharp.fluid
                    .src,
              srcSet: '',
              sizes: '',
              aspectRatio: 1,
            },
          },
          slug: item.fields.slug,
          category: {
            title: item.fields.shortAddress ? item.fields.shortAddress : '',
          },
        }))

      setRelated(normalised)
    }
    getNearby()
  }, [])

  const googlePlace = usePlacesApi({ placeId: googlePlacesId, ignoreOpeningHours: ignoreOpeningHours })
  const googleMapText = useResourceTextData('global.googlemaps', 'Google maps')

  const getOpeningHours = (): void => {
    triggerEvent({
      action: 'check_open_status',
      category: 'cafe_finder',
      label: title,
    })
    setIgnoreOpeningHours(false)
  }

  return (
    <Layout>
      <Seo
        title={title}
        imageUrl={
          hasImage
            ? images && images[0].fluid.src.replace(/^\/\//, 'https://')
            : undefined
        }
        description={metaDescription?.metaDescription}
        url={props.location.href}
        postTitle={useResourceTextData(
          'cafe.metaPostTitle',
          'Allpress Espresso Cafe Finder'
        )}
      />
      <ArticleHeader hasImage={hasImage} type="cafe">
        <Container>
          <Breadcrumbs>
            <Link to="/find">
              <ChevDown />
              {useResourceTextData(
                'global.backtosearch',
                'Back to search results'
              )}
            </Link>
          </Breadcrumbs>
          <Card
            size="lg"
            title={title}
            subTitle={{ title: shortAddress }}
            tags={filterTags}
            image={images && images[0]}
            hero
          />
        </Container>
      </ArticleHeader>

      <ArticleBody hasImage={hasImage} type="cafe">
        <Container>
          <div className="row">
            <div className="meta">
              { showTableOfContents && <TableOfContents />}
              {!hasImage && (
                <div className="tablet-title">
                  <SubHead className="sub-title">{shortAddress}</SubHead>
                  <h1 className="title">{title}</h1>
                </div>
              )}

              <CafeMeta>
                <div className="has-icon">
                  {longAddress && (
                    <p>
                      <Icon size="medium">
                        <Marker />
                      </Icon>
                      {renderLineBreaks(longAddress?.longAddress)}
                    </p>
                  )}
                  {phone && (
                    <p>
                      <a href={`tel:${phone}`}>{phone}</a>
                    </p>
                  )}
                  {googlePlace?.url && (
                    <p>
                      <Icon className="link-icon">
                        <LinkIcon />
                      </Icon>
                      <Link className="map-link" to={googlePlace?.url}>
                        {googleMapText}
                      </Link>
                    </p>
                  )}
                </div>
                {(openingHours || googlePlace?.openStatus) && (
                  <div className="has-icon">
                    <p>
                      <Icon size="medium">
                        <Time />
                      </Icon>
                      {renderLineBreaks(openingHours?.openingHours)}
                      {openingHours && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                      <OpenStatus status={googlePlace?.openStatus} getOpeningHours={getOpeningHours} />
                    </p>
                  </div>
                )}

                <div>
                  {instagram && (
                    <div className="has-icon">
                      <p>
                        <Icon size="medium">
                          <Ig />
                        </Icon>
                        <Link to={`https://www.instagram.com/${instagram}`}>
                          @{instagram}
                        </Link>
                      </p>
                    </div>
                  )}
                  {facebook && (
                    <div className="has-icon">
                      <p>
                        <Icon size="medium">
                          <Facebook />
                        </Icon>
                        <Link to={`https://www.facebook.com/${facebook}`}>
                          @{facebook}
                        </Link>
                      </p>
                    </div>
                  )}
                  {twitter && (
                    <div className="has-icon">
                      <p>
                        <Icon size="medium">
                          <Twitter />
                        </Icon>
                        <Link to={`https://www.twitter.com/${twitter}`}>
                          @{twitter}
                        </Link>
                      </p>
                    </div>
                  )}
                  {homepage && (
                    <div className="has-icon">
                      <p>
                        <Icon size="medium">
                          <Web />
                        </Icon>
                        <Link className="web-link" to={homepage}>
                          {homepage.match(/http.:\/\/(.*)\//)?.[1] || homepage}
                        </Link>
                      </p>
                    </div>
                  )}
                </div>
              </CafeMeta>
            </div>
            <div className="content">
              {!hasImage && (
                <div className="tablet-title">
                  <SubHead className="sub-title">{shortAddress}</SubHead>
                  <h1 className="title">{title}</h1>
                </div>
              )}
              {leadingParagraph?.leadingParagraph && (
                <p className="lead">{leadingParagraph?.leadingParagraph}</p>
              )}
              {body && renderDocumentToReactComponents(body)}
            </div>
            <div />
          </div>
        </Container>
      </ArticleBody>

      {related && (
        <ArticleFooter>
          <Carousel
            intro={useResourceTextData(
              'global.relatedCafeText',
              'We partner with many amazing independent cafes – here are some more suggestions for you.'
            )}
            items={related}
            linkPrefix="find"
          />
        </ArticleFooter>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ContentFulCafe($slug: String, $locale: String) {
    contentfulCafe(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      title
      slug
      node_locale
      instagram
      facebook
      twitter
      homepage
      phone
      location {
        lat
        lon
      }
      images {
        fluid {
          ...Image
        }
      }
      leadingParagraph {
        leadingParagraph
      }
      showTableOfContents
      body {
        raw
        references {
          ... on Node {
            ... on ContentfulImagesContentBlock {
              ...ContentfulImagesContentBlock
            }
            ... on ContentfulVideo {
              contentful_id
              internal {
                type
              }
              url
            }
            ... on ContentfulButton {
              contentful_id
              title
              regionallink: link {
                title
                nzSlug
                auSlug
                ukSlug
                sgSlug
                jpSlug
              }
              internal {
                type
              }
            }
            ... on ContentfulSecondaryButton {
              contentful_id
              title
              regionallink: link {
                ukSlug
                auSlug
                nzSlug
                jpSlug
                sgSlug
              }
            }
          }
        }
      }
      longAddress {
        longAddress
      }
      shortAddress
      openingHours {
        openingHours
      }
      metaDescription {
        metaDescription
      }
      filterTags {
        title
        slug
        internal {
          type
        }
      }
      googlePlacesId
    }
    allFile(filter: { relativeDirectory: { eq: "placeholder" } }) {
      nodes {
        relativeDirectory
        childImageSharp {
          fluid(maxWidth: 800) {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
`

export default CafeTemplate
